'use strict';

/**
 * 
 * @param {string} sParam 
 * @returns param value if exists
 */
function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return false;
};

/**
 * To get a cookie with click id value
 * @param {string} cname 
 * @returns cookie
 */

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
        }
    }
    return false;
}

/**
 * To set a cookie with click id
 * @param {string} cname 
 * @param {string} cvalue 
 * @param {number} exdays 
 */
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    var domain = window.location.host;
    if(domain.indexOf('www.')>=0){
        domain = domain.replace('www.','.');
    }
    document.cookie = cname + '=' + cvalue + ';' + expires + ';domain='+domain+';path=/;secure';
}
/**
 * Function to detect a URL param and create a cookie with param value as cookie value
 */
function initCookieCreate() {
    var clickId = getUrlParameter('cjevent');
    if(clickId !== false && clickId.length <= 50 ){
        //setCookie('cje', clickId, 395);
    }
}

function initS2SPost(){
    if($('.js-cj-orders2s').length>0 && $('.js-cj-orders2s').val() === 'true'){
        var clickID = getCookie('cje');
        var url = $('.js-cj-orders2s').data('s2surl');
        if(clickID !== false){
            url = url+'&clickid='+clickID;
        }else{
            url = url+'&clickid='+'false';
        }
        setTimeout(function() {
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    //No logging
                },
                error: function (err) {
                    //No error Handling
                }
            });
        },4000);
    }
}

function intiConversionTag() {
    if ( $('.js-cj-conversiontag').length > 0 && $('.js-cj-conversiontag').val() !== 'false') {
        var orderData = JSON.parse( $('.js-cj-conversiontag').val() );
        var tagUrl = $('.js-cj-conversiontag').data('pixelurl');
        if(tagUrl){
            var clickID = getCookie('cje');
            tagUrl += 'CID=' + orderData.enterpriseId + '&TYPE=' + orderData.actionTrackerId + '&METHOD=IMG';
            if (clickID !== false) {
                tagUrl += '&CJEVENT=' + clickID;
            }
            tagUrl += '&OID=' + orderData.orderId + '&CURRENCY=' + orderData.currency + '&COUPON=' + orderData.coupon;

            var itemsArr = orderData.items;
            var eachItem;
            for (var i=0; i < itemsArr.length ; i++ ) {
                eachItem = itemsArr[i];
                tagUrl += '&ITEM' + (i+1) + '=' + eachItem.itemId + '&AMT' + (i+1) + '=' + eachItem.unitPrice + '&QTY' + (i+1) + '=' + eachItem.quantity + '&DCNT' + (i+1) + '=' + eachItem.discount;
            }
            tagUrl += '&DISCOUNT=' + orderData.discount + '&CUST_COUNTRY=' + orderData.customerCountry + '&BRAND=' + orderData.brand + '&CATEGORY=' + orderData.category + '&COUPON_DISCOUNT=' + orderData.couponDiscount + '&COUPON_TYPE=' + orderData.couponType + '&PROMOTION=' + orderData.promotion;
            $( "body" ).prepend( "<img class='d-none' height='1' width='20' src=" + encodeURI(tagUrl) + " />" );
        }
    }
}

module.exports = {
    initCookieCreate: initCookieCreate,
    initS2SPost : initS2SPost,
    intiConversionTag : intiConversionTag
};
